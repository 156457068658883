import { UseFiltersProps } from "@epcnetwork/core-ui-kit";

import { ColumnsType } from "types";
import { DEFAULT_OFFSET } from "constants/query.constants";
import { GetJobsQuery } from "api/jobs";

export const jobListColumns: ColumnsType = [
  { label: "Creator", required: true },
  { label: "Created at", queryKey: "createdAt" },
  { label: "Status", required: true, queryKey: "status" },
  { label: "Duration" },
  { label: "Mergex import" },
  { label: "" },
];

export const TABLE_NAME = "jobs-table";

export const initialFilters: UseFiltersProps<GetJobsQuery> = {
  initialState: {
    search: "",
    limit: "25",
    offset: DEFAULT_OFFSET,
  },
};
