import { DashboardStatsState } from "api/dashboard/dashboard.types";
import { DashboardEventData } from "pages/dashboard/dashboard.types";
import { List } from "models";

export const suppressionStatusUpdater =
  <T extends DashboardStatsState>({ id, prevStatus, newStatus }: DashboardEventData) =>
  (previousState: List<T> | null) => {
    if (!previousState) return previousState;

    const newData = previousState.data.map((project) => {
      if (project.id === id) {
        if (prevStatus) {
          return {
            ...project,
            [prevStatus]: +project[prevStatus] > 0 ? +project[prevStatus] - 1 : project[prevStatus],
            [newStatus]: project[newStatus] + 1,
          };
        }
        return { ...project, [newStatus]: project[newStatus] + 1 };
      }
      return project;
    });

    return { ...previousState, data: newData };
  };
