import { io, Socket } from "socket.io-client";
import { useSelector } from "react-redux";
import { useRef, useState } from "react";
import { notification, useDidMount } from "@epcnetwork/core-ui-kit";

import { RootState } from "store";
import { appEnvironment } from "config/environment.config";
import {
  JobListenerHandlers,
  JobListenerEventsKeys,
  JobIdArgument,
  JobSocketInstance,
  JobEventEmitter,
} from "./use-job-socket.types";

const SOCKET_JOB_NAMESPACE = "/suppressions";

export const useJobSocketHook = ({ jobId }: JobIdArgument) => {
  const socketEmitFactory = useRef<JobEventEmitter | null>(null);
  const socket = useRef<JobSocketInstance | null>(null);

  const [isConnected, setConnected] = useState<boolean>(false);

  const { token } = useSelector((state: RootState) => state.auth);

  const emitFactory = (socket: JobSocketInstance): JobEventEmitter => ({
    joinRoomEmit: (data: JobIdArgument) => {
      socket.emit("roomJoin", data);
    },
  });

  const listenerHandlers: Partial<JobListenerHandlers> = {
    connect: () => {
      setConnected(true);

      if (jobId) {
        const roomJoinData: JobIdArgument = { jobId: +jobId };
        socketEmitFactory.current?.joinRoomEmit(roomJoinData);
      }
    },
    connectError: () => {
      setConnected(false);
    },
    disconnect: () => {
      setConnected(false);
    },
    exception: (error: Error) => {
      notification.error("Socket exception", "Error while connecting to the socket. " + error);
    },
  };

  const addJobListener =
    (socketInstance: Socket<Partial<JobListenerHandlers>>) =>
    <E extends JobListenerEventsKeys>(event: E) => {
      socketInstance.on<JobListenerEventsKeys>(event, listenerHandlers[event]);

      return () => {
        socketInstance.off<JobListenerEventsKeys>(event, listenerHandlers[event]);
      };
    };

  const mountListeners = (socketInstance: Socket<JobListenerHandlers>) => {
    const connectUnmount = addJobListener(socketInstance)("connect");
    const connectErrorUnmount = addJobListener(socketInstance)("connectError");
    const disconnectUnmount = addJobListener(socketInstance)("disconnect");
    const exceptionUnmount = addJobListener(socketInstance)("exception");

    return () => {
      connectUnmount();
      connectErrorUnmount();
      disconnectUnmount();
      exceptionUnmount();
    };
  };

  useDidMount(() => {
    socket.current = io(appEnvironment.apiUrl + SOCKET_JOB_NAMESPACE, {
      autoConnect: false,
      extraHeaders: {
        authorization: `Bearer ${token}`,
      },
    });

    const unmountListeners = mountListeners(socket.current);
    socketEmitFactory.current = emitFactory(socket.current);
    socket.current.connect();

    return () => {
      socket.current?.disconnect();
      unmountListeners();

      socket.current = null;
    };
  });

  return { socket: socket.current, isConnected };
};
