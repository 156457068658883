import React, { Reducer, useReducer } from "react";

type EspListsData = {
  selectedProjects: number[];
  selectedDataExtensions: number[];
  selectedRemarketyAccounts: number[];
  selectedMarketoAccounts: number[];
  selectedGreenArrowAccounts: number[];
  selectedInsiderAccounts: number[];
  selectedBlueshiftAccounts: number[];
};

type ListAction = {
  type: ListsActionType;
  payload: number[];
};

type ResetAction = {
  type: "resetAll";
};

type ListsActionType =
  | "setIterable"
  | "setSalesforce"
  | "setRemarkety"
  | "setMarketo"
  | "setGreenArrow"
  | "setInsider"
  | "setBlueshift"
  | "resetAll";

const initialLists: EspListsData = {
  selectedProjects: [],
  selectedDataExtensions: [],
  selectedRemarketyAccounts: [],
  selectedMarketoAccounts: [],
  selectedGreenArrowAccounts: [],
  selectedInsiderAccounts: [],
  selectedBlueshiftAccounts: [],
};

type CreateSuppressionContextType = {
  state: EspListsData;
  dispatch: React.Dispatch<ListAction | ResetAction>;
};

export const CreateSuppressionContext = React.createContext<CreateSuppressionContextType>({
  state: initialLists,
  dispatch: () => null,
});

interface Props {
  children: React.ReactNode;
}

export const CreateSuppressionContextProvider: React.FC<Props> = ({ children }) => {
  const listsReducer = (state: EspListsData, action: ListAction | ResetAction) => {
    switch (action.type) {
      case "setIterable":
        return { ...state, selectedProjects: action.payload };
      case "setSalesforce":
        return { ...state, selectedDataExtensions: action.payload };
      case "setRemarkety":
        return { ...state, selectedRemarketyAccounts: action.payload };
      case "setMarketo":
        return { ...state, selectedMarketoAccounts: action.payload };
      case "setGreenArrow":
        return { ...state, selectedGreenArrowAccounts: action.payload };
      case "setInsider":
        return { ...state, selectedInsiderAccounts: action.payload };
      case "setBlueshift":
        return { ...state, selectedBlueshiftAccounts: action.payload };
      case "resetAll":
        return initialLists;
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer<Reducer<EspListsData, ListAction | ResetAction>>(listsReducer, initialLists);

  const value: CreateSuppressionContextType = {
    state,
    dispatch,
  };

  return <CreateSuppressionContext.Provider value={value}>{children}</CreateSuppressionContext.Provider>;
};
