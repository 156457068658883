import { EspListOptions } from "./lists-select.types";

export const initialEspListsOptions: EspListOptions = {
  iterable: [],
  salesforce: [],
  remarkety: [],
  marketo: [],
  insider: [],
  greenarrow: [],
  blueshift: [],
};
