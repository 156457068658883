import React, { useState } from "react";
import { Container } from "@epcnetwork/core-ui-kit";
import { useFetch } from "@hyper-fetch/react";
import { useDidUpdate } from "@better-hooks/lifecycle";
import cn from "classnames";

import { SuppressionsStatus } from "./suppressions-status/suppressions-status";
import { steps } from "./dashboard.tour";
import { InfoBox } from "pages/dashboard/info-box/info-box";
import { getActiveEsps, getDashboardGeneral } from "api";
import { DashboardGeneralDataState } from "api/dashboard/dashboard.types";
import { useSocket, useTour } from "hooks";
import { DashboardListenerHandlers, DashboardGeneralData } from "./dashboard.types";
import { integrations } from "constants/integrations.constants";

import styles from "./dashboard.module.scss";

export const DashboardPage: React.FC = () => {
  useTour({
    name: "dashboard",
    steps,
  });

  const { socket, isConnected } = useSocket<DashboardListenerHandlers>({ namespace: "/dashboard" });

  const [generalData, setGeneralData] = useState<DashboardGeneralDataState | null>(null);

  const { data: activeEsps, loading: espsLoading } = useFetch(getActiveEsps);

  const { loading, onSuccess } = useFetch(getDashboardGeneral);
  onSuccess(({ response }) => {
    setGeneralData(response);
  });

  useDidUpdate(
    () => {
      const handleGeneralDataUpdate = ({ esp, prevStatus, newStatus }: DashboardGeneralData) => {
        setGeneralData((previousData) => {
          if (previousData) {
            const updatedState = prevStatus
              ? {
                  ...previousData[esp],
                  [prevStatus]:
                    +previousData[esp][prevStatus] > 0
                      ? +previousData[esp][prevStatus] - 1
                      : previousData[esp][prevStatus],
                  [newStatus]: previousData[esp][newStatus] + 1,
                }
              : { ...previousData[esp], [newStatus]: previousData[esp][newStatus] + 1 };

            return { ...previousData, [esp]: updatedState };
          }

          return previousData;
        });
      };

      socket?.on("dashboardGeneralUpdate", handleGeneralDataUpdate);

      return () => {
        socket?.off("dashboardGeneralUpdate", handleGeneralDataUpdate);
      };
    },
    [isConnected],
    true,
  );

  return (
    <>
      <Container contentClassName={styles.container}>
        <div className={cn(styles.boxContainer, "dashboard-general")}>
          {integrations.map((integration) => (
            <InfoBox
              key={integration}
              integration={integration}
              success={generalData ? generalData[integration].success : "-"}
              failed={generalData ? generalData[integration].failed : "-"}
              inProgress={generalData ? generalData[integration].inProgress : "-"}
              description="in the last 24 hours"
              loading={loading}
              isEspActive={activeEsps?.[integration] || false}
              espsLoading={espsLoading}
            />
          ))}
        </div>
        <div className={styles.content}>
          <SuppressionsStatus socket={socket} />
        </div>
      </Container>
    </>
  );
};
