import React, { useRef, useState } from "react";
import { useFetch } from "@hyper-fetch/react";
import { Search, SelectOption, Table, useFilters, usePagination } from "@epcnetwork/core-ui-kit";
import { useDidUpdate } from "@better-hooks/lifecycle";

import { Container, StatusFilter, TableError, TableNoContent } from "components";
import { useSocket, useTablePagination, useTour } from "hooks";
import { JobUpdateData, JobListListenerHandlers } from "./job-list.types";
import { getJobs, GetJobsQuery } from "api";
import { JobItem } from "./table-item/job-item";
import { getInitialStorageFilters, updateItem } from "utils";
import { initialFilters, jobListColumns, TABLE_NAME } from "./job-list.constants";
import { JobModel, List } from "models";
import { steps } from "./jobs-list.tour";

import styles from "./jobs-list.module.scss";

export const JobsListPage: React.FC = () => {
  useTour({
    name: "job-list",
    steps,
  });

  const pageSizeRef = useRef<number>(10);

  const { socket, isConnected } = useSocket<JobListListenerHandlers>({ namespace: "/suppressions" });
  const { query, setQueryParams, searchValue, setSearch } = useFilters<GetJobsQuery>(
    getInitialStorageFilters<GetJobsQuery>(TABLE_NAME, initialFilters),
  );

  const [jobs, setJobs] = useState<List<JobModel> | null>(null);

  const { loading, refetch, error, onSuccess } = useFetch(getJobs.setQueryParams(query));
  onSuccess(({ response }) => setJobs(response));

  const pagination = usePagination({ listPayload: jobs });
  const onElementsPerPageChange = (value: number) => {
    pagination.onElementsPerPageChange(value);
    pageSizeRef.current = value;
  };
  const { currentElementsPerPage, handlePerPageChange } = useTablePagination({
    elementsPerPage: pagination.elementsPerPage,
    onElementsPerPageChange,
    tableName: TABLE_NAME,
  });

  useDidUpdate(
    () => {
      const handleJobCreate = (job: JobModel) => {
        setJobs((prevState) => {
          if (!prevState) return null;

          let previousJobs = prevState.data;
          if (prevState.data.length >= pageSizeRef.current) {
            previousJobs = prevState.data.slice(0, -1);
          }

          const newJobs = [job, ...previousJobs];
          return { ...prevState, data: newJobs, total: prevState.total + 1 };
        });
      };

      const handleJobListUpdate = ({ suppressionJobId, ...data }: JobUpdateData) => {
        setJobs((prevState) => {
          if (!prevState) return null;

          const updatedState = prevState.data.map((job) => {
            if (job.id === suppressionJobId) {
              return Object.assign(
                { ...job },
                ...Object.entries(data)
                  .filter(Boolean)
                  .map(([key, value]) => ({ [key]: value })),
              );
            }

            return job;
          });

          return { ...prevState, data: updatedState };
        });
      };

      socket?.on("jobUpdate", handleJobListUpdate);
      socket?.on("jobCreate", handleJobCreate);

      return () => {
        socket?.off("jobUpdate", handleJobListUpdate);
        socket?.off("jobCreate", handleJobCreate);
      };
    },
    [isConnected],
    true,
  );

  const handleTerminateSuccess = (job: JobModel) => {
    if (jobs) updateItem(job, jobs, setJobs);
  };

  const handleStatusChange = (value: SelectOption<string> | null) => {
    if (value) {
      setQueryParams({ ...query, limit: "50", offset: "0", search: "", status: value.value });
    }
  };

  return (
    <Container>
      <div className={styles.header}>
        <Search searchValue={searchValue} setSearch={setSearch} />

        <StatusFilter type="job" selectedStatus={query?.status || ""} onChange={handleStatusChange} />
      </div>

      <Table
        entityName={TABLE_NAME}
        columns={jobListColumns}
        list={jobs?.data}
        error={error?.message}
        refresh={() => refetch()}
        loading={loading}
        resetColumnsOnMount={false}
        isTabTable
        customNoContent={<TableNoContent withBackground />}
        customError={<TableError description={error?.message} withBackground />}
        pagination={{
          ...pagination,
          elementsPerPage: currentElementsPerPage,
          onElementsPerPageChange: handlePerPageChange,
        }}
        row={(job) => <JobItem job={job} onTerminateSuccess={handleTerminateSuccess} />}
      />
    </Container>
  );
};
