import { Integration } from "types";

export const integrations: Integration[] = [
  "iterable",
  "salesforce",
  "remarkety",
  "marketo",
  "greenarrow",
  "insider",
  "blueshift",
];
