import { FC } from "react";
import { TableRow, TableCell, TextEllipsis } from "@epcnetwork/core-ui-kit";

import { Check, Circle, Failed } from "assets";
import { Badge } from "components";
import { ProjectStatsState } from "api/dashboard/dashboard.types";

import styles from "../suppressions-status.module.scss";

type ProjectItemProps = {
  index: number;
  data: ProjectStatsState;
};

export const IterableTableItem: FC<ProjectItemProps> = ({ index, data }) => {
  return (
    <TableRow id={index} className={styles.row}>
      <TableCell className={styles.name}>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left">
          {data.name}
        </TextEllipsis>
      </TableCell>

      <TableCell>
        <Badge>Iterable</Badge>
      </TableCell>

      <TableCell>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left">
          {data.account}
        </TextEllipsis>
      </TableCell>

      <TableCell>
        <Badge className={styles.completed} variant="success">
          <Check />
          <span>{data.success}</span>
        </Badge>
      </TableCell>

      <TableCell>
        <Badge className={styles.failed} variant="error">
          <Failed />
          <span>{data.failed}</span>
        </Badge>
      </TableCell>

      <TableCell>
        <Badge className={styles.pending} variant="default">
          <Circle />
          <span>{data.inProgress}</span>
        </Badge>
      </TableCell>
    </TableRow>
  );
};
