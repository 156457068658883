import React, { useContext, useMemo, useState } from "react";
import { Button, Label, Select, SelectOption, TextEllipsis } from "@epcnetwork/core-ui-kit";
import { useNavigate } from "react-router-dom";
import { useFetch } from "@hyper-fetch/react";
import { List, CheckCircle } from "lucide-react";
import cn from "classnames";
import { MinusIcon } from "@radix-ui/react-icons";

import {
  getActiveDataExtensions,
  getActiveEsps,
  getActiveProjects,
  getBlueshiftActiveAccounts,
  getGreenArrowActiveAccounts,
  getInsiderActiveAccounts,
  getMarketoActiveAccounts,
  getRemarketyActiveAccounts,
} from "api";
import { BUSINESS_UNIT_DETAILS_PAGE } from "constants/routes.constants";
import { CreateSuppressionContext } from "../create-suppression.context";
import { EspListOptions } from "./lists-select.types";
import { initialEspListsOptions } from "./lists-select.constants";
import { Integration } from "types";
import { Plus } from "assets";

import styles from "./lists-select.module.scss";

export const ListsSelect: React.FC = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(CreateSuppressionContext);

  const [options, setOptions] = useState<EspListOptions>(initialEspListsOptions);

  const updateOptions = (integration: Integration, newOptions: SelectOption<number>[]) => {
    setOptions((prevOptions) => ({ ...prevOptions, [integration]: newOptions }));
  };

  const { data: activeEsps } = useFetch(getActiveEsps);

  const { data: projects, onSuccess: onProjectsSuccess, loading: projectsLoading } = useFetch(getActiveProjects);
  onProjectsSuccess(({ response }) => {
    const options = response.map((project) => ({ label: project.name, value: project.id }));
    updateOptions("iterable", options);
    dispatch({ type: "setIterable", payload: response.map((item) => item.id) });
  });

  const {
    data: dataExtensions,
    onSuccess: onDataExtensionsSuccess,
    loading: dataExtensionsLoading,
  } = useFetch(getActiveDataExtensions);
  onDataExtensionsSuccess(({ response }) => {
    const options = response.map((project) => ({ label: project.name, value: project.id }));
    updateOptions("salesforce", options);
    dispatch({ type: "setSalesforce", payload: response.map((item) => item.id) });
  });

  const { onSuccess: onRemarketyAccountsSuccess, loading: remarketyAccountsLoading } =
    useFetch(getRemarketyActiveAccounts);
  onRemarketyAccountsSuccess(({ response }) => {
    const options = response.map((project) => ({ label: project.name, value: project.id }));
    updateOptions("remarkety", options);
    dispatch({ type: "setRemarkety", payload: response.map((item) => item.id) });
  });

  const { onSuccess: onMarketoAccountsSuccess, loading: marketoAccountsLoading } = useFetch(getMarketoActiveAccounts);
  onMarketoAccountsSuccess(({ response }) => {
    const options = response.map((project) => ({ label: project.name, value: project.id }));
    updateOptions("marketo", options);
    dispatch({ type: "setMarketo", payload: response.map((item) => item.id) });
  });

  const { onSuccess: onGreenArrowAccountsSuccess, loading: greenarrowAccountsLoading } =
    useFetch(getGreenArrowActiveAccounts);
  onGreenArrowAccountsSuccess(({ response }) => {
    const options = response.map((project) => ({ label: project.name, value: project.id }));
    updateOptions("greenarrow", options);
    dispatch({ type: "setGreenArrow", payload: response.map((item) => item.id) });
  });

  const { onSuccess: onInsiderAccountsSuccess, loading: insiderAccountsLoading } = useFetch(getInsiderActiveAccounts);
  onInsiderAccountsSuccess(({ response }) => {
    const options = response.map((project) => ({ label: project.name, value: project.id }));
    updateOptions("insider", options);
    dispatch({ type: "setInsider", payload: response.map((item) => item.id) });
  });

  const { onSuccess: onBlueshiftAccountsSuccess, loading: blueshiftAccountsLoading } =
    useFetch(getBlueshiftActiveAccounts);
  onBlueshiftAccountsSuccess(({ response }) => {
    const options = response.map((project) => ({ label: project.name, value: project.id }));
    updateOptions("blueshift", options);
    dispatch({ type: "setBlueshift", payload: response.map((item) => item.id) });
  });

  const accounts = useMemo(() => {
    if (!projects || projects?.length === 0) return [];

    return projects.reduce<{ id: number; name: string }[]>((acc, value) => {
      if (!acc.some((item) => item.id === value.accountId)) {
        acc.push({ id: value.accountId, name: value.accountName });
      }

      return acc;
    }, []);
  }, [projects]);

  const allFromAccountSelected = (accountId: number) => {
    if (!projects || projects?.length === 0 || accounts.length === 0) return false;

    const accountProjects = projects.filter((project) => project.accountId === accountId);

    return accountProjects.every((project) => state.selectedProjects.includes(project.id));
  };

  const handleSelectFromAccount = (accountId: number) => () => {
    const accountProjectsIds =
      projects?.filter((project) => project.accountId === accountId)?.map((project) => project.id) || [];

    const haveAllSelected = accountProjectsIds.every((projectId) => state.selectedProjects.includes(projectId));

    if (haveAllSelected) {
      // filter all projects from account
      dispatch({
        type: "setIterable",
        payload: state.selectedProjects.filter((project) => !accountProjectsIds?.includes(project)),
      });
      return;
    }

    const filteredProjects = () => {
      const filteredProjects = state.selectedProjects.filter((project) => !accountProjectsIds?.includes(project));
      return [...filteredProjects, ...accountProjectsIds];
    };

    dispatch({
      type: "setIterable",
      payload: filteredProjects(),
    });
  };

  const businessUnits = useMemo(() => {
    if (!dataExtensions || dataExtensions?.length === 0) return [];

    return dataExtensions.reduce<{ id: number; name: string }[]>((acc, value) => {
      if (!acc.some((item) => item.id === value.businessUnitId)) {
        acc.push({ id: value.businessUnitId, name: value.businessUnitName });
      }

      return acc;
    }, []);
  }, [dataExtensions]);

  const allFromBusinessUnitSelected = (businessUnitId: number) => {
    if (!dataExtensions || dataExtensions?.length === 0 || accounts.length === 0) return false;

    const unitDataExtensions = dataExtensions.filter(
      (dataExtension) => dataExtension.businessUnitId === businessUnitId,
    );

    return unitDataExtensions.every((dataExtension) => state.selectedDataExtensions.includes(dataExtension.id));
  };

  const handleSelectFromBusinessUnit = (businessUnitId: number) => () => {
    const unitDataExtensionsIds =
      dataExtensions
        ?.filter((dataExtension) => dataExtension.businessUnitId === businessUnitId)
        ?.map((dataExtension) => dataExtension.id) || [];

    const haveAllSelected = unitDataExtensionsIds.every((dataExtension) =>
      state.selectedDataExtensions.includes(dataExtension),
    );

    if (haveAllSelected) {
      // filter all data extensions from business unit
      dispatch({
        type: "setSalesforce",
        payload: state.selectedDataExtensions.filter(
          (dataExtension) => !unitDataExtensionsIds?.includes(dataExtension),
        ),
      });
      return;
    }

    // filter already selected data extensions from a business unit and then select all from that particular business unit
    const filteredDataExtensions = () => {
      const filteredDataExtensions = state.selectedDataExtensions.filter(
        (project) => !unitDataExtensionsIds?.includes(project),
      );
      return [...filteredDataExtensions, ...unitDataExtensionsIds];
    };

    dispatch({ type: "setSalesforce", payload: filteredDataExtensions() });
  };

  const projectsEmpty = projects?.length === 0 && !projectsLoading;
  const dataExtensionsEmpty = dataExtensions?.length === 0 && !dataExtensionsLoading;

  return (
    <div className="suppress-details">
      <div>
        <button
          type="button"
          className={cn(styles.button, styles.buttonWrapper)}
          onClick={() => dispatch({ type: "resetAll" })}
        >
          <MinusIcon />
          <TextEllipsis>Reset all selected</TextEllipsis>
        </button>
        <div className={styles.labelWrapper}>
          <div>
            <Label text="Projects to suppress" isInputLabel />

            <p className={styles.projectDescription}>Select projects these suppressions will be added to</p>
          </div>
        </div>

        <div className={styles.grid}>
          {accounts.map((account) => {
            const selectedAll = allFromAccountSelected(account.id);

            return (
              <button
                key={account.id}
                type="button"
                className={cn(styles.button, { [styles.buttonActive]: selectedAll })}
                onClick={handleSelectFromAccount(account.id)}
              >
                {selectedAll ? <CheckCircle width={14} height={14} /> : <List width={14} height={14} />}
                <TextEllipsis>
                  {selectedAll ? "Selected" : "Select"} all from <strong>{account.name}</strong>
                </TextEllipsis>
              </button>
            );
          })}
        </div>
        <Select
          name="projectIds"
          options={options.iterable}
          isSearchable
          isMulti
          selectedOptionsKeys={state.selectedProjects}
          onChange={(options) => dispatch({ type: "setIterable", payload: options.map((item) => item.value) })}
          searchPlaceholder="Search projects"
          asyncOptions={{ loading: projectsLoading }}
          disabled={projectsLoading}
        />
      </div>

      {activeEsps?.salesforce && (
        <div className={styles.projectSelect}>
          {dataExtensionsEmpty && (
            <>
              <div>
                <Label text="Data extensions to suppress" isInputLabel />
                <p className={styles.projectDescription}>
                  You currently have no active data extensions. Click the button bellow, select the business unit and
                  activate or create a new data extension.
                </p>

                <Button appearance="primary" onClick={() => navigate(BUSINESS_UNIT_DETAILS_PAGE.path)} btnSize="small">
                  <Plus />
                  Create first data extension
                </Button>
              </div>
            </>
          )}

          {!dataExtensionsEmpty && (
            <>
              <div className={styles.labelWrapper}>
                <div>
                  <Label text="Data extensions to suppress" isInputLabel />
                  <p className={styles.projectDescription}>Select data extensions to which suppresses will be added</p>
                </div>
              </div>
              <div className={styles.grid}>
                {businessUnits.map((businessUnit) => {
                  const selectedAll = allFromBusinessUnitSelected(businessUnit.id);

                  return (
                    <button
                      key={businessUnit.id}
                      type="button"
                      className={cn(styles.button, { [styles.buttonActive]: selectedAll })}
                      onClick={handleSelectFromBusinessUnit(businessUnit.id)}
                    >
                      {selectedAll ? <CheckCircle width={14} height={14} /> : <List width={14} height={14} />}
                      <TextEllipsis>
                        {selectedAll ? "Selected" : "Select"} all from <strong>{businessUnit.name}</strong>
                      </TextEllipsis>
                    </button>
                  );
                })}
              </div>

              <Select
                name="dataExtensionIds"
                options={options.salesforce}
                selectedOptionsKeys={state.selectedDataExtensions}
                onChange={(options) => dispatch({ type: "setSalesforce", payload: options.map((item) => item.value) })}
                isSearchable
                isMulti
                searchPlaceholder="Search data extensions"
                asyncOptions={{ loading: dataExtensionsLoading }}
                disabled={dataExtensionsLoading}
              />
            </>
          )}
        </div>
      )}

      {activeEsps?.remarkety && options.remarkety.length > 0 && (
        <div>
          <div className={styles.labelWrapper}>
            <div>
              <Label text="Remarkety accounts to suppress" isInputLabel />
              <p className={styles.projectDescription}>
                Select Remarkety accounts that will be included in the suppression
              </p>
            </div>
          </div>

          <Select
            name="remarketyAccountsIds"
            options={options.remarkety}
            selectedOptionsKeys={state.selectedRemarketyAccounts}
            onChange={(options) => dispatch({ type: "setRemarkety", payload: options.map((item) => item.value) })}
            isSearchable
            isMulti
            searchPlaceholder="Search Remarkety accounts"
            asyncOptions={{ loading: remarketyAccountsLoading }}
            disabled={remarketyAccountsLoading}
          />
        </div>
      )}

      {activeEsps?.marketo && options.marketo.length > 0 && (
        <div>
          <div className={styles.labelWrapper}>
            <div>
              <Label text="Marketo accounts to suppress" isInputLabel />
              <p className={styles.projectDescription}>
                Select Marketo accounts that will be included in the suppression
              </p>
            </div>
          </div>

          <Select
            name="marketoAccountsIds"
            options={options.marketo}
            selectedOptionsKeys={state.selectedMarketoAccounts}
            onChange={(options) => dispatch({ type: "setMarketo", payload: options.map((item) => item.value) })}
            isSearchable
            isMulti
            searchPlaceholder="Search Marketo accounts"
            asyncOptions={{ loading: marketoAccountsLoading }}
            disabled={marketoAccountsLoading}
          />
        </div>
      )}

      {activeEsps?.greenarrow && options.greenarrow.length > 0 && (
        <div>
          <div className={styles.labelWrapper}>
            <div>
              <Label text="GreenArrow accounts to suppress" isInputLabel />
              <p className={styles.projectDescription}>
                Select GreenArrow accounts that will be included in the suppression
              </p>
            </div>
          </div>

          <Select
            name="greenarrowAccountsIds"
            options={options.greenarrow}
            selectedOptionsKeys={state.selectedGreenArrowAccounts}
            onChange={(options) => dispatch({ type: "setGreenArrow", payload: options.map((item) => item.value) })}
            isSearchable
            isMulti
            searchPlaceholder="Search GreenArrow accounts"
            asyncOptions={{ loading: greenarrowAccountsLoading }}
            disabled={greenarrowAccountsLoading}
          />
        </div>
      )}

      {activeEsps?.insider && options.insider.length > 0 && (
        <div>
          <div className={styles.labelWrapper}>
            <div>
              <Label text="Insider accounts to suppress" isInputLabel />
              <p className={styles.projectDescription}>
                Select Insider accounts that will be included in the suppression
              </p>
            </div>
          </div>

          <Select
            name="insiderAccountsIds"
            options={options.insider}
            selectedOptionsKeys={state.selectedInsiderAccounts}
            onChange={(options) => dispatch({ type: "setInsider", payload: options.map((item) => item.value) })}
            isSearchable
            isMulti
            searchPlaceholder="Search Insider accounts"
            asyncOptions={{ loading: insiderAccountsLoading }}
            disabled={insiderAccountsLoading}
          />
        </div>
      )}

      {activeEsps?.blueshift && options.blueshift.length > 0 && (
        <div>
          <div className={styles.labelWrapper}>
            <div>
              <Label text="Blueshift accounts to suppress" isInputLabel />
              <p className={styles.projectDescription}>
                Select Blueshift accounts that will be included in the suppression
              </p>
            </div>
          </div>

          <Select
            name="blueshiftAccountIds"
            options={options.blueshift}
            selectedOptionsKeys={state.selectedBlueshiftAccounts}
            onChange={(options) => dispatch({ type: "setBlueshift", payload: options.map((item) => item.value) })}
            isSearchable
            isMulti
            searchPlaceholder="Search Blueshift accounts"
            asyncOptions={{ loading: blueshiftAccountsLoading }}
            disabled={blueshiftAccountsLoading}
          />
        </div>
      )}
    </div>
  );
};
